<template>
  <div class="resource-reservation-settings">
    <div class="font-bold text-lg text-primary-100">預約時段設定</div>
    <p class="sub-title">
      您可設定營業時間內均開放預約，或排除營業時間內的特定時段
      <a class="cursor-pointer" @click="showModal = true">(查看營業時間)</a>
    </p>
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item prop="enableTimeOff">
        <el-radio-group v-model="formData.enableTimeOff">
          <el-radio v-for="item in enableTimeOffOptions" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="formData.enableTimeOff && formData.timeOffConfig">
        <el-form-item label="排除月份">
          <el-select v-model="formData.timeOffConfig.months" placeholder="請選擇月份" multiple>
            <el-option
              v-for="item in monthOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排除星期">
          <el-select v-model="formData.timeOffConfig.weekdays" placeholder="請選擇星期" multiple>
            <el-option
              v-for="item in weeksOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排除特定日期" prop="timeOffConfig.dateRangeSettings">
          <el-button class="mb-[8px]" plain @click="addDateRange">新增日期</el-button>
          <div v-for="(item ,index) in formData.timeOffConfig.dateRangeSettings" :key="index" class="date-range mb-[8px]">
            <el-date-picker
              v-model="formData.timeOffConfig.dateRangeSettings[index]"
              type="daterange"
              range-separator="至"
              start-placeholder="開始日期"
              end-placeholder="結束日期"
            />
            <i class="el-icon-close" @click="formData.timeOffConfig.dateRangeSettings.splice(index, 1)" />
          </div>
        </el-form-item>
        <el-form-item label="排除特定時段" prop="timeOffConfig.timeRangeSettings">
          <el-button class="mb-[8px]" plain @click="addTimeRange">新增時段</el-button>
          <div v-for="(item ,index) in formData.timeOffConfig.timeRangeSettings" :key="index" class="time-range mb-[8px]">
            <div class="flex-1">
              <el-date-picker
                v-model="formData.timeOffConfig.timeRangeSettings[index].date"
                class="date-picker"
                type="date"
                placeholder="請選擇日期"
              />
              <div class="flex">
                <el-time-select
                  v-model="formData.timeOffConfig.timeRangeSettings[index].timeStart"
                  data-cy="start-time-select"
                  class="w-full flex-1"
                  placeholder="起始時間"
                  :picker-options="{
                    start: '00:00',
                    step: '00:10',
                    end: '24:00',
                  }"
                  :disabled="!formData.timeOffConfig.timeRangeSettings[index].date"
                />
                <el-time-select
                  v-model="formData.timeOffConfig.timeRangeSettings[index].timeEnd"
                  data-cy="end-time-select"
                  class="w-full flex-1 ml-2"
                  placeholder="結束時間"
                  :picker-options="{
                    start: formData.timeOffConfig.timeRangeSettings[index].timeStart,
                    step: '00:10',
                    end: '24:00',
                    minTime: formData.timeOffConfig.timeRangeSettings[index].timeStart,
                  }"
                  :disabled="!formData.timeOffConfig.timeRangeSettings[index].date"
                />
              </div>
            </div>
            <i class="el-icon-close" @click="formData.timeOffConfig.timeRangeSettings.splice(index, 1)" />
          </div>
        </el-form-item>
      </template>
    </el-form>
    <ShopBusinessTimeDialog
      title="營業時間"
      subtitle="如欲調整營業時間，請至「基本參數設定 > 營業時間設定 > 分店營業時間」變更設定"
      :visible="showModal"
      btn="關閉"
      @close="showModal = false"
    />
  </div>
</template>

<script>
// import ManualSalesRecordBasicSettingsEditModal from './ManualSalesRecordBasicSettingsEditModal.vue'
import { defineComponent, computed, onMounted, watch, nextTick, ref } from 'vue'
import { get } from 'lodash'
import { noEmptyRules, noEmptyDateTimeInterval, noEmptyDateTimeRangeInterval } from '@/validation'
import FormsContext from '../formsContext'
import { simpleSyncFormData, useBaseForm } from '@/use/useForm'
import { monthOptions, weeksOptions } from '@/config/date'
import ShopBusinessTimeDialog from './ShopBusinessTimeDialog.vue'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'ResourceReservationSettings',
  components: { ShopBusinessTimeDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { initFormData, formData, formRef } = useBaseForm()
    const enableTimeOffOptions = [
      { label: '營業時間內均開放預約', value: false },
      { label: '排除營業時間內特定時段', value: true },
    ]
    const showModal = ref(false)

    // const empty = { start: '', end: '' }
    const emptyDate = ['', '']
    const emptyDateTime = { date: '', timeStart: '', timeEnd: '' }
    initFormData({
      enableTimeOff: false,
      timeOffConfig: {
        months: [],
        weekdays: [],
        dateRangeSettings: [],
        timeRangeSettings: [],
      },
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        enableTimeOff: [noEmptyRules()],
        timeOffConfig: {
          dateRangeSettings: [noEmptyDateTimeInterval()],
          timeRangeSettings: [noEmptyDateTimeRangeInterval()],
        },
      }

      return rules
    })

    const syncFormData = (data) => {
      simpleSyncFormData(data, formData)
      if (get(formData, 'timeOffConfig')) {
        if (get(formData, 'timeOffConfig.dateRangeSettings').length > 0) {
          formData.timeOffConfig.dateRangeSettings = formData.timeOffConfig.dateRangeSettings.map((item) => {
            return [item.start, item.end]
          })
        }
        if (get(formData, 'timeOffConfig.timeRangeSettings').length > 0) {
          formData.timeOffConfig.timeRangeSettings = formData.timeOffConfig.timeRangeSettings.map((item) => {
            return {
              date: item.start,
              timeStart: getTimeFromDate(item.start),
              timeEnd: getTimeFromDate(item.end),
            }
          })
        }
      } else {
        formData.timeOffConfig = {
          months: [],
          weekdays: [],
          dateRangeSettings: [],
          timeRangeSettings: [],
        }
      }
    }

    const compactData = computed(() => {
      let data = {
        enableTimeOff: get(formData, 'enableTimeOff'),
        timeOffConfig: {
          months: [],
          weekdays: [],
          dateRangeSettings: [],
          timeRangeSettings: [],
        },
      }
      if (get(formData, 'enableTimeOff')) {
        const dateRangeSettings = get(formData, 'timeOffConfig') && get(formData, 'timeOffConfig.dateRangeSettings').length > 0 ? formData.timeOffConfig.dateRangeSettings.map((item) => {
          if (!item) {
            return {
              start: null,
              end: null,
            }
          }
          return {
            start: item[0] ? dayjs(item[0]).startOf('day').toISOString() : '',
            end: item[1] ? dayjs(item[1]).endOf('day').toISOString() : '',
          }
        }) : []
        const timeRangeSettings = get(formData, 'timeOffConfig') && get(formData, 'timeOffConfig.timeRangeSettings').length > 0 ? formData.timeOffConfig.timeRangeSettings.map((item) => {
          return {
            start: addTimeToDateTime(item.date, item.timeStart),
            end: addTimeToDateTime(item.date, item.timeEnd),
          }
        }) : []
        data = {
          enableTimeOff: get(formData, 'enableTimeOff'),
          timeOffConfig: {
            months: get(formData, 'timeOffConfig.months') || null,
            weekdays: get(formData, 'timeOffConfig.weekdays') || null,
            dateRangeSettings,
            timeRangeSettings,
          },
        }
      }
      return data
    })

    const getTimeFromDate = (dateString) => {
      const date = new Date(dateString)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return hours + ':' + minutes
    }

    const addTimeToDateTime = (dateTime, timeToAdd) => {
      if (dateTime && timeToAdd) {
        const [hoursToAdd, minutesToAdd] = timeToAdd.split(':')

        const result = new Date(dateTime)
        result.setHours(result.getHours() + parseInt(hoursToAdd, 10))
        result.setMinutes(result.getMinutes() + parseInt(minutesToAdd, 10))

        return result
      }
    }

    watch(compactData, () => {
      FormsContext.setFormData('formRef2', { ...compactData.value })
    })

    const addDateRange = () => {
      formData.timeOffConfig.dateRangeSettings.push(JSON.parse(JSON.stringify(emptyDate)))
    }
    const addTimeRange = () => {
      formData.timeOffConfig.timeRangeSettings.push(JSON.parse(JSON.stringify(emptyDateTime)))
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('formRef2', formRef.value)
      FormsContext.setFormData('formRef2', { enableTimeOff: false })
      if (get(props.configData, 'id')) syncFormData(props.configData)
    })

    return {
      formRef,
      formData,
      formRules,
      enableTimeOffOptions,
      addDateRange,
      addTimeRange,
      monthOptions,
      weeksOptions,
      showModal,
    }
  },
})
</script>

  <style lang="postcss" scoped>
.sub-title {
  font-size: 12px;
  color: var(--gray-80);
  font-weight: 500;
  margin-bottom: 32px;
}
.el-radio {
  display: flex;
  margin-bottom: 8px;
}
.date-range {
  @apply bg-[#F5F7FA] rounded-[8px] p-[12px] max-w-[560px] flex items-center
}
.time-range {
  @apply bg-[#F5F7FA] rounded-[8px] p-[12px] max-w-[560px] flex items-center
}
.date-picker {
  @apply w-full mb-[8px]
}
.el-range-editor {
  flex: 1;
}
.el-icon-close {
  @apply w-[24px] h-[24px] flex justify-center items-center ml-[8px]
}
  </style>
